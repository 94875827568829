export const headers = {};
export const PAGE_LENGTH = [10, 25, 50, 100];
const sort = {
  Descending: "desc",
  Ascending: "asc",
};
export const FILTER = {
  page: 1,
  limit: 10,
  status: "All",
  searchData: "",
  sort: "id",
  order: sort.Ascending,
  state: "",
};
export const initialState = {
  sortBy: [{ id: "id", desc: true }],
  pageSize: FILTER.limit,
  pageIndex: 0,
};
export const SHOW_FILTER_STATUS = [
  {value: "", label: "-- Select Status --"},
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];
export const STATUS = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];
export const clientFilesAllow = "Only .jpg, .png, .jpeg files are allowed";
export const importFilesAllow = "Only .csv files are allowed.";
export const acceptImport = ".csv";
// AddEditForm constants
export const contact_type = [
  { value: "1", label: "Primary" },
  { value: "2", label: "Secondary" },
];

// image accept extension
export const accept = "image/png, image/jpg, image/jpeg";

// resource accept extension
export const acceptResource = "image/png, image/jpg, image/jpeg, .doc, .pdf";
export const filesallow =
  "Only .jpg, .png, .jpeg, .doc, .pdf files are allowed";

// Messages
export const fetchClientsErrorMessage = "Failed to fetch clients";
export const deleteClientMessage = "Client deleted successfully.";
