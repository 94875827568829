import React from "react";

const Late = () => {
  return (
    <>
      <span className="tab-badge late">late</span>
    </>
  );
};
export default Late;
