import React from "react";

const Resolved = () => {
  return (
    <>
    
      <span className="tab-badge completed">Resolved</span>
    </>
  );
};
export default Resolved;
