import React from "react"

const Refuse = () => {
  return (
    <>
      <span className="tab-badge not-approved">refused</span>
    </>
  )
}
export default Refuse
