import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  timezone: "Asia/Kolkata"
}

export const timeZoneSlice = createSlice({
  name: "timeZone",
  initialState,
  reducers: {
    setTimeZone: (state, action) => {
      state.timezone = action.payload.timezone
    },
    clearTimeZone: (state) => {
      state.timezone = null
    }
  }
})

export const { setTimeZone, clearTimeZone } = timeZoneSlice.actions

export default timeZoneSlice.reducer
