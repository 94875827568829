import React, { useState, useEffect, useRef } from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import { Controller } from "react-hook-form"
import { IconExternalLink, IconEye, IconEyeOff } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import SelectBox from "../SelectBox"
import "react-datepicker/dist/react-datepicker.css"
import { getYear, getMonth } from "date-fns"
import range from "lodash/range" // Optional, can also use native JS functions
const isMobileDevice = () => {
  return (
    typeof window !== 'undefined' && 
    (navigator.maxTouchPoints > 0 ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  );
};
export const FormField = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => (
  <Form.Group as={Col} md={size}>
    <Form.Label className={`${required ? "required" : ""}`}>
      {label}{" "}
      <Form.Text className="form-label-description" muted>
        {hint}
      </Form.Text>
    </Form.Label>
    <Form.Control
      type={type}
      isInvalid={error ? true : false}
      placeholder={placeholder}
      {...register(name)}
      autoFocus={autoFocus}
      {...props}
    />
    {downloadLink && (
      <Form.Text as="div" className="text-truncate text-break text-end">
        {downloadLink}
      </Form.Text>
    )}
    <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
  </Form.Group>
)
export const FormFieldPassword = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(true)
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <InputGroup className={`input-group-flat ${error ? "is-invalid" : ""}`}>
        <Form.Control
          type={`${isShow ? "password" : "text"}`}
          isInvalid={error ? true : false}
          placeholder={placeholder}
          {...register(name)}
          autoFocus={autoFocus}
          {...props}
        />
        <InputGroup.Text as="span" className={`${error && "border-danger"}`}>
          <Link
            to={""}
            tabIndex="-1"
            className={`${error && "text-danger"}`}
            onClick={() => setIsShow(!isShow)}
          >
            {isShow ? <IconEye stroke={1.5} size={20} /> : <IconEyeOff stroke={1.5} size={20} />}
          </Link>
        </InputGroup.Text>
      </InputGroup>
      {downloadLink && (
        <Form.Text as="div" className="text-truncate text-break text-end">
          {downloadLink}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldCheck = ({
  size = "6",
  label,
  name,
  type = "radio",
  options,
  register,
  error = "",
  inline = true,
  required,
  className = ""
}) => {
  return (
    <Form.Group as={Col} md={size} className={className}>
      <Form.Label className={`${required && "required"}`}>{label}</Form.Label>
      {options.map((option) => (
        <Form.Check
          inline={inline}
          key={option.value || option.label}
          type={type}
          className={`mt-2 ${error ? "is-invalid" : ""}`}
        >
          <Form.Check.Input
            type={type}
            {...register(name)}
            value={option.value}
            isInvalid={error ? true : false}
            id={option.value}
          />
          <Form.Check.Label htmlFor={option.value}>
            {option.label}{" "}
            {option.document && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_UPLOAD_BASE_URL}${option.document}`}
                className="ms-2 d-inline-block text-truncate text-break position-absolute"
                style={{ maxWidth: "calc(100% - 50%)" }}
              >
                <IconExternalLink className="icon me-1" />
                {option.document_name}
              </a>
            )}
          </Form.Check.Label>
        </Form.Check>
      ))}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldSelect = ({
  size = "6",
  label,
  name,
  placeholder = "Select",
  control,
  options = [],
  error = "",
  isClearable = false,
  isSearchable = false,
  required,
  ...props
}) => {
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectBox
            {...field}
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={options}
            className={`form-control p-0 border-0 ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            {...props}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldDatePicker = ({
  label,
  name,
  control,
  error = "",
  placeholder = "MM/DD/YYYY",
  size = "6",
  hint = "",
  autoFocus,
  required,
  showTimeSelect=false,
  disabled=false,
  dateFormat="MM/dd/yyyy",
  ...props
}) => {
  const [isYearOpen, setIsYearOpen] = useState(false)
  const yearRef = useRef(null)
  const yearItemRefs = useRef({});
  const currentYear = new Date().getFullYear()
  const years = range(1950, currentYear + 4)
  
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const toggleYearDropdown = () => {
    setIsYearOpen(!isYearOpen)
  }

  const handleYearChange = (changeYear, year) => {
    changeYear(year)
    setIsYearOpen(false)
  }

  useEffect(() => {
    if (isYearOpen && yearItemRefs.current[getYear(new Date())]) {
      yearItemRefs.current[getYear(new Date())].scrollIntoView({
        behavior: "auto",
        block: "center"
      });
    }
  }, [isYearOpen]);

  const commonStyles = {
    appearance: "none",
    border: "1px solid #aeaeae",
    borderRadius: "3px",
    padding: "5px 10px",
    marginRight: "5px",
    cursor: "pointer",
    fontSize: "14px"
  }

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => {
    const selectedYear = getYear(date);
    const selectedMonth = getMonth(date);
  
    const nextMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
  
    const maxYear = currentYear + 3;
    const isNextMonthDisabled = nextYear > maxYear;
  
  return (
    <div className="custom-date-picker-header">
      <div className="date-piker-header">
        <div
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-previous"
        ></div>
        <h6>
          {months[getMonth(date)]} {getYear(date)}
        </h6>
        <button
          onClick={(e)=>{
            e.preventDefault();
            increaseMonth(e)
          }}
          disabled={isNextMonthDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-next"
        ></button>
      </div>
      <div className="month-year-grp">
        <div style={{ position: "relative", marginRight: "5px" }}>
          <span className="arrow-down"></span>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            style={commonStyles}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div style={{ position: "relative" }}>
          <div
            className="year-dropdown-btn"
            onClick={toggleYearDropdown}
            ref={yearRef}
            style={commonStyles}
          >
            {getYear(date)}
          </div>
          {isYearOpen && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
                scrollbarWidth: "thin"
              }}
              className="year-dropdown"
            >
              {years.map((year) => (
                <div
                  key={year}
                  onClick={() => handleYearChange(changeYear, year)}
                  ref={(el) => (yearItemRefs.current[year] = el)} 
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    textAlign: "left",
                    color: year === getYear(date) ? "#fff" : "#000",
                    backgroundColor: year === getYear(date) ? "#006ce1" : "white"
                  }}
                >
                  {year}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )}

  return (
    <Form.Group md={size}>
      <Form.Label>
        {label}{" "}
        {hint && (
          <Form.Text className="form-label-description" muted>
            {hint}
          </Form.Text>
        )}
      </Form.Label>
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            renderCustomHeader={CustomHeader}
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            dateFormat={dateFormat}
            wrapperClassName="w-100"
            className={`form-control ${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            showTimeSelect={showTimeSelect}
            onCalendarClose={() => setIsYearOpen(false)} 
            {...props}
            onFocus={(e) => {
              if (isMobileDevice()) {
                e.target.readOnly = true;
              }
            }}
            disabled={disabled}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  )
}




export const TimePicker = ({
  label,
  name,
  control,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  timeIntervals=15,
  autoFocus,
  required,
  ...props
}) => {
  const fieldName = name;
  const formatTimeWithLeadingZero = (time) => {
    return moment(time).format("hh:mm A"); 
  };
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value ? moment(field.value, "hh:mm A").toDate() : null}
            onChange={(time) => {
              const formattedTime = time ? moment(time).format("hh:mm A") : '';
              if(time){
                field.onChange(formattedTime);
              }
              else{
                field.onChange('');
              }
            }}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            timeCaption="Time"
            dateFormat="hh:mm a" // Update dateFormat to use lowercase 'a'
            timeFormat="hh:mm a" // Update timeFormat similarly
            formatTime={formatTimeWithLeadingZero} 
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  );
};



export const FormFieldDatePickers = ({
  label,
  name,
  setValue=() => {},
  control,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  maxDate = false,
  ...props
}) => {
  const fieldName = name;
  const [isYearOpen, setIsYearOpen] = useState(false);
  const yearRef = useRef(null);
  const yearItemRefs = useRef({});
  const currentYear = new Date().getFullYear();
  const years = range(1950, currentYear+1);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const toggleYearDropdown = () => {
    setIsYearOpen(!isYearOpen);
  };

  const handleYearChange = (changeYear, year) => {
    changeYear(year);
    setIsYearOpen(false);
  };
  useEffect(() => {
    if (isYearOpen && yearItemRefs.current[getYear(new Date())]) {
      yearItemRefs.current[getYear(new Date())].scrollIntoView({
        behavior: "auto",
        block: "center"
      });
    }
  }, [isYearOpen]);
  const commonStyles = {
    appearance: "none",
    border: "1px solid #aeaeae",
    borderRadius: "3px",
    padding: "5px 10px",
    marginRight: "5px",
    cursor: "pointer",
    fontSize: "14px",
  };

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => {
    const selectedYear = getYear(date);
    const selectedMonth = getMonth(date);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
  
    // Disable the next button if it's past the current year and month
    const isNextMonthDisabled = 
      (selectedYear === currentYear && selectedMonth >= currentMonth) || 
      selectedYear > currentYear;
  return (
    <div className="custom-date-picker-header">
      <div className="date-piker-header">
        <div
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-previous"
        ></div>
        <h6>
          {months[getMonth(date)]} {getYear(date)}
        </h6>
        <button
          onClick={(e)=>{
            e.preventDefault();
            increaseMonth(e)
          }}
          disabled={isNextMonthDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-next"
        ></button>
      </div>
      <div className="month-year-grp">
        <div style={{ position: "relative", marginRight: "5px" }}>
          <span className="arrow-down"></span>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            style={commonStyles}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div style={{ position: "relative" }}>
          <div
            className="year-dropdown-btn"
            onClick={toggleYearDropdown}
            ref={yearRef}
            style={commonStyles}
          >
            {getYear(date)}
          </div>
          {isYearOpen && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
                scrollbarWidth: "thin"
              }}
              className="year-dropdown"
            >
              {years.map((year) => (
                <div
                  key={year}
                  onClick={() => handleYearChange(changeYear, year)}
                  ref={(el) => (yearItemRefs.current[year] = el)} 
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    textAlign: "left",
                    color: year === getYear(date) ? "#fff" : "#000",
                    backgroundColor: year === getYear(date) ? "#006ce1" : "white"
                  }}
                >
                  {year}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )}
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            renderCustomHeader={CustomHeader} 
            selected={field.value && field.value != null ? moment(field.value, "MM/DD/YYYY").toDate() : null}
            onChange={(date) => {
              console.log("🚀 ~ date:", date)
              //old - DD-MMM-YYYY
              if(date){

                const formattedDate = moment(date).format("MM/DD/YYYY");
                field.onChange(formattedDate);
                setValue(name,formattedDate)
              }
              else{
                field.onChange(null);
                setValue(name,null)
              }
            }}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="MM/dd/yyyy"
            fixedHeight
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            onFocus={(e) => {
              if (isMobileDevice()) {
                e.target.readOnly = true;
              }
            }}
            onCalendarClose={() => setIsYearOpen(false)} 
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  );
};