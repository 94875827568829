import React from "react"

const Divider = (props) => {
  const MenuLink = props.MenuLink
  if (MenuLink === "/clients") {
    return <span className="menu-seperator-title">Manage</span>
  }
  if (MenuLink === "/recruitment" || MenuLink === "/sa/users") {
    return <span className="menu-seperator-title">others</span>
  }
}

export default Divider
