import axios from "axios";
const headers = {
  headers: { "Content-Type": "multipart/form-data" },
};
export const getAll = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get("/users" + filter)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getOne = async (id) => {
  return await axios
    .get("users/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const create = async (payload) => {
  return await axios
    .post("users/create", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const update = async (id, payload) => {
  return await axios
    .put("users/" + id, payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const removeUserProfile = async (id) => {
  return await axios
    .put("users/removeProfile/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const remove = async (id) => {
  return await axios
    .delete("users/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const multiUpdate = async (payload) => {
  return await axios
    .put("users/multi-update", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const multiRemove = async (payload) => {
  return await axios
    .put("users/multi-delete", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getProfile = async (payload) => {
  return await axios
    .get("users/profile", payload)
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const updateProfile = async (payload) => {
  return await axios
    .post("users/updateProfile", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const updatePassword = async (payload) => {
  return await axios
    .put("profile/set-password/", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const removeAccount = async () => {
  return await axios
    .put("profile/delete-profile")
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const fieldrepUpddatePassword = async (id, payload) => {
  return await axios
    .put("users/password/fieldrep/" + id, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
export const userUpdatePassword = async (id, payload) => {
  // headers: {
  //   "Content-Type": "multipart/form-data",
  //   accesscode: `${userData.accesscode}`,
  //   Authorization: `Bearer ${userData.token_type}`,
  // },
  const headers = {
    headers: {
      accesscode: "sa",
    },
  };

  return await axios
    .put("/users/updatePassword/" + id, payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
export const fieldRepPassword = async (id, payload) => {
  return await axios
    .put("/users/password/fieldrepChangePassword/" + id, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteuserProfile = async (id) => {
  return await axios
    .put("users/removeProfile/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteuserProfileRep = async (id) => {
  return await axios
    .put("users/removeFieldrepProfile/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
export const userUpddatePassword = async (id, payload, headers) => {
  return await axios
    .put("users/updatePassword/" + id, payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};
