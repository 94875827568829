import React from "react";

const Inactive = (props) => {
  return (
    <>
      <div className="table-data text-center">
        <span className="tab-badge not-approved">inactive</span>
      </div>
    </>
  );
};
export default Inactive;
