import { createSlice } from "@reduxjs/toolkit";

// Initial state with isSa set to false
const initialRoleState = {
  isSa: null,
};

// Create the slice
export const checkRoleForPathSlice = createSlice({
  name: "checkRoleForPath",
  initialState: initialRoleState,
  reducers: {
    // Action to set isSa to true
    setIsSaTrue: (state) => {
      state.isSa = true;
    },
    // Action to set isSa to false
    setIsSaFalse: (state) => {
      state.isSa = false;
    },
  },
});

// Export actions
export const { setIsSaTrue, setIsSaFalse } = checkRoleForPathSlice.actions;

// Export reducer
export default checkRoleForPathSlice.reducer;
