export const headers = {};
export const PAGE_LENGTH = [10, 25, 50, 100];
const sort = {
  Descending: "desc",
  Ascending: "asc",
};
export const FILTER = {
  page: 1,
  limit: 10,
  status: "All",
  searchData: "",
  sort: "id",
  order: sort.Descending,
  state: "",
  project_id: "",
  start_date: "",
  deadline_date: "",
};
export const initialState = {
  sortBy: [{ id: "id", desc: true }],
  pageSize: FILTER.limit,
  pageIndex: 0,
};
export const STATUS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];
export const SHOW_FILTER_STATUS = [
  {value: "", label: "-- Select Status --"},
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];
export const clientFilesAllow = "Only .jpg, .png, .jpeg files are allowed";
export const importFilesAllow = "Only .csv files are allowed.";
export const acceptImport = ".csv";
// AddEditForm constants
export const contact_type = [
  { value: "1", label: "Primary" },
  { value: "2", label: "Secondary" },
];

// resource accept extension
export const acceptResource = "image/png, image/jpg, image/jpeg, .doc, .pdf";

// Messages
export const fetchRoundsErrorMessage = "Failed to fetch rounds";
export const deleteRoundMessage = "Round deleted successfully.";

// experiance radio buttons
export const experiance = [
  { label: 0, value: "0" },
  { label: 1, value: "1" },
  { label: 2, value: "2" },
  { label: 3, value: "3" },
  { label: 4, value: "4" },
  { label: 5, value: "5" },
  { label: 5, value: "5+" },
];

export const availability = [
  { label: "Monday", value: "Mon" },
  { label: "Tuesday", value: "Tue" },
  { label: "Wednesday", value: "Wed" },
  { label: "Thursday", value: "Thu" },
  { label: "Friday", value: "Fri" },
  { label: "Saturday", value: "Sat" },
  { label: "Sunday", value: "Sun" },
];

export const hasCamera = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

// Instructions type
export const TYPE = [
  { value: "Schedule", label: "0" },
  { value: "Offer", label: "1" },
  { value: "Both", label: "2" },
];
export const schedule = "Schedule"
export const offer = "Offer"
export const both = "Both"
// image accept extension
export const accept =
  "image/png, image/jpg, image/jpeg, .pdf, .txt, .xls, .xlsx, .doc, .docx";
export const filesallow =
  "Only .jpg, .png, .txt, .pdf, .xls, .xlsx, .doc, .docx files are allowed";

