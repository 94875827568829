import axios from "axios"
//import { headers } from "../Constants/constants"
export const getAllBroadcastMessages = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
          })
          .join("&")
      : "")
  return await axios
    .get("/notifications/getAdminsNotifications" + filter)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const getAll = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
          })
          .join("&")
      : "")
  return await axios
    .get("/notifications/getAllAdminNotifications" + filter)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const getOne = async (id) => {
  return await axios
    .get("sa/users/" + id)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const create = async (payload) => {
  return await axios
    .post("notifications/createNotification", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const remove = async (id) => {
  return await axios
    .delete("notifications/" + id)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const multiRemove = async (payload) => {
  return await axios
    .put("notifications/multipleDelete", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const deleteMultiNotification = async (payload) => {
  return await axios
    .post("notifications/multipleDelete", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const deleteSingleNotification = async (id, payload) => {
  return await axios
    .delete("notifications/" + id, payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const deleteSingleBroadcastMessage = async (payload) => {
  return await axios
    .post("notifications/singleAdminDeleteNotification", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const deleteMultiBroadcastMessage = async (payload) => {
  return await axios
    .post("notifications/multipleDeleteNotifications ", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const multiReadBroadcastMessage = async (payload) => {
  return await axios
    .post("notifications/multipleReadNotifications ", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const getAllUsers = async () => {
  return await axios
    .post("users/getAllUsers")
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const getAllFieldreps = async () => {
  return await axios
    .get("fieldrep/getAllActiveFieldreps")
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

// ------------------------------ For user   -----------------------
export const getAllUserNotifications = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
          })
          .join("&")
      : "")
  return await axios
    .get("/notifications/getAdminUsersNotifications" + filter)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const deleteSingleBroadcastMessageUser = async (payload) => {
  return await axios
    .post("notifications/singleAdminUserDeleteNotification", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const deleteMultiBroadcastMessageUser = async (payload) => {
  return await axios
    .post("notifications/multipleFieldrepDeleteNotifications ", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const multiReadBroadcastMessageUser = async (payload) => {
  return await axios
    .post("notifications/multipleAdminUsersReadNotifications", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

// ------------------------------- count   -------------------------------------
export const getNotificationCount = async (type) => {
  let endpoint

  switch (type) {
    case "A":
      endpoint = "notifications/getAdminNotificationCount"
      break
    case "AA":
      endpoint = "notifications/getAdminUserNotificationCount"
      break
    case "F":
      endpoint = "notifications/getAdminFieldrepNotificationCount "
      break
    case "S":
      endpoint = "notifications/getGuestNotificationCount"
      break
    case "SU":
      endpoint = "notifications/getSuperadminUserNotificationCount"
      break
    default:
      throw new Error("Invalid type")
  }

  return await axios
    .get(endpoint)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
