
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBJJY5fqdJ2fOuUTr2PdegZAcH8ozMYodw",
    authDomain: "ars-v2-new.firebaseapp.com",
    projectId: "ars-v2-new",
    storageBucket: "ars-v2-new.appspot.com",
    messagingSenderId: "180725522417",
    appId: "1:180725522417:web:16725d6708c78f68c26e50",
    measurementId: "G-570CMEY7RB",
  };
const firebaseNotificationKeyPair = "BJgT-2qY7ekU0jgul3KdCVfxmBdcZknLlIniJDfgtqV3m1MOOx651E1loqMU-FK_wVp8pWzCfMJa6WMXunj50C4";

const app = initializeApp(firebaseConfig);

let messaging;

isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(app);
  } else {
    console.warn("Firebase Messaging is not supported by this browser or domain.");
  }
});



export const requestPermission = () => {
  if (!messaging) return;
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, { vapidKey: firebaseNotificationKeyPair })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!messaging) return;
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
