import React from "react";

const Primary = () => {
  return (
    <>
      <span className="badge-outline">primary</span>
    </>
  );
};
export default Primary;
