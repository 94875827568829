
const authMiddleware = (store) => (next) => (action) => {
  if (action.type === 'authUser/resetAuthData' && !action.fromStorageEvent) {
    localStorage.setItem('logout', Date.now());
  }

  return next(action);
};

export default authMiddleware;

