import React from "react";

const IsCompleted = () => {
  return (
    <>
      <span className="tab-badge reported">reported</span>
    </>
  );
};
export default IsCompleted;
