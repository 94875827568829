import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  count:0
};

export const notificationCountSlice = createSlice({
  name: "notificationCount",
  initialState,
  reducers: {
    updateCount: (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
    //   state.success = true;
    },
   
  },
});

export const { updateCount } = notificationCountSlice.actions;

export default notificationCountSlice.reducer;
