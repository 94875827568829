import React from "react";

const Cancelled = () => {
  return (
    <>
      <span className="tab-badge late">Cancelled</span>
    </>
  );
};
export default Cancelled;
