import axios from "axios";
import { headers } from "../Constants/constants";

export const updatePermissionSa = async () => {
  return await axios
    .post("/sa/roles/getAllPermissionsUser", headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
