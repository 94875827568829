import React from "react";

import Completed from "../../components/Status/Completed";
import Reported from "../../components/Status/Reported";
import Schedule from "../../components/Status/Scheduled";
import Refuse from "../../components/Status/Refuse";
import NotApproved from "../../components/Status/NotApproved";
import Offered from "../../components/Status/Offered";
import Late from "../../components/Status/Late";
import Pending from "../../components/Status/Pending";
import Active from "../../components/Status/Active";
import Inactive from "../../components/Status/Inactive";
import Primary from "../../components/Status/Primary";
import Secondary from "../../components/Status/Secondary";
import Cancelled from "./Cancelled";
import Progress from "./Progress";
import Resolved from "./Resolved";

const Status = (props) => {
  return (
    <>
      {(() => {
        if (props.status === "approved") {
          return <Completed />;
        } else if (props.status === "scheduled") {
          return <Schedule />;
        } else if (props.status === "refuse") {
          return <Refuse />;
        } else if (props.status === "offered") {
          return <Offered />;
        } else if (props.status === "not_approved") {
          return <NotApproved />;
        } else if (props.status === "reported") {
          return <Reported />;
        } else if (props.status === "late") {
          return <Late />;
        } else if (props.status === "pending") {
          return <Pending />;
        } else if (props.status === true) {
          return <Active />;
        } else if (props.status === false) {
          return <Inactive />;
        } else if (props.type === "Primary") {
          return <Primary />;
        } else if (props.type === "Secondary") {
          return <Secondary />;
        }
      })()}
    </>
  );
};
export default Status;



export const StatusReportIssue = (props) => {
  
    return (
      <>
        {(() => {
          if (props.status === "cancelled") {
            return <Cancelled/>;
          } else if (props.status === "in progress") {
            return <Progress/>;
          } else if (props.status === "resolved") {
            return <Resolved/>;
          } else if (props.status === "pending") {
            return <Pending />;
          } else if (props.status === true) {
            return <Active />;
          } else if (props.status === false) {
            return <Inactive />;
          } 
        })()}
      </>
    );
  
}
