import React, { useEffect, useState } from "react";
import toastSuccessIcon from "../../assets/images/toast-success.svg";
import toastErrorIcon from "../../assets/images/toast-error.svg";
import { ToastContainer } from "react-bootstrap";
import CloseSvg from "../../assets/images/close.svg";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { resetToaster } from "../../store/slices/toaster/toasterslice";

const Toaster = ({
  isHeader = false,
  text = "",
  position = "top-center",
  headerText = "",
  delay = "3000",
}) => {
  const dispatch = useDispatch();
  const showToaster = useSelector((state) => state.ars.toasterReducer);

  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    let timer;
    setType(showToaster.type);
    setShow(showToaster.show);
    setTitle(showToaster.title);
    if (showToaster.show) {
      timer = setTimeout(() => {
        setShow(false);
        dispatch(resetToaster());
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showToaster, delay]);

  return (
    <ToastContainer>
      <Toast
        // bg={showToaster.type.toLowerCase() || type.toLowerCase()}
        onClose={() => setShow(false)}
        animation
        show={show}
        delay={showToaster.delay || delay}
        autohide
      >
        <Toast.Body>
          {/* {showToaster.message || text} */}
          <div className="toast-wrapper pe-0">
            {type === "success" ? (
              <>
                {(showToaster.isHeader || isHeader) && (
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      onClick={() => setShow(false)}
                    />
                    <strong className="me-auto">{headerText}</strong>
                  </Toast.Header>
                )}
                <span>
                  <img src={toastSuccessIcon} alt="success" />
                </span>
                <div className="toast-content me-auto">
                  <h6>{type}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <svg style={{minWidth:'13px'}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.664 10.0442C12.114 10.4942 12.114 11.2141 11.664 11.664C11.4421 11.886 11.1481 12 10.8541 12C10.5602 12 10.2662 11.886 10.0442 11.664L6.00075 7.62055L1.95726 11.664C1.72928 11.886 1.44132 12 1.14736 12C0.853393 12 0.55943 11.886 0.337458 11.664C-0.112486 11.2141 -0.112486 10.4942 0.337458 10.0442L4.38095 6.00075L0.337458 1.95726C-0.112486 1.50731 -0.112486 0.787402 0.337458 0.337458C0.781402 -0.112486 1.50731 -0.112486 1.95726 0.337458L6.00075 4.38095L10.0442 0.337458C10.4942 -0.112486 11.2141 -0.112486 11.664 0.337458C12.114 0.781402 12.114 1.50731 11.664 1.95726L7.62055 6.00075L11.664 10.0442Z" fill="#999999"/>
</svg>

                {/* <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                /> */}
              </>
            ) : type === "notify" ? (
              <>
                <span className="primary">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#333333"
                    xmlns="httpwww.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2667 4.99C14.207 2.06 12.2838 0 10.5862 0C10.3213 0 10.1054 0.05 9.88955 0.11C9.40874 0.27 8.98681 0.65 8.72187 1.14C5.52303 4.83 1.10745 6.51 1.05839 6.51C-0.00135174 6.89 -0.325161 8.62 0.361707 10.52C0.950451 12.15 2.01019 13.18 2.97181 13.18C3.07974 13.18 3.23674 13.18 3.34468 13.13C3.45261 13.08 4.04136 12.97 5.1011 12.8L6.16084 15.08C6.2099 15.19 6.31783 15.3 6.48465 15.35L8.192 15.95C8.24107 15.95 8.29994 16 8.349 16C8.45694 16 8.56487 15.95 8.67281 15.89C8.82981 15.78 8.88868 15.56 8.88868 15.4L8.51581 12.31C9.8503 12.2 11.332 12.15 12.8235 12.26H12.9314C13.1473 12.31 13.3533 12.37 13.5692 12.37C13.8341 12.37 14.05 12.32 14.2659 12.26C15.1686 11.93 15.8064 10.9 15.9732 9.44C16.0812 8.14 15.8653 6.51 15.2766 4.99H15.2667ZM7.01451 14.37L6.21971 12.58L7.44626 12.42L7.7112 14.59L7.01451 14.37ZM10.8512 9.17C10.4783 8.57 10.1054 7.87 9.79142 7C9.52649 6.19 9.31062 5.43 9.26155 4.67C9.41855 4.67 9.63443 4.83 9.8503 5C10.2232 5.38 10.596 5.98 10.8119 6.63C11.2338 7.88 11.1357 8.85 10.861 9.18L10.8512 9.17ZM3.07974 12.09H3.03068C2.65781 12.2 1.863 11.49 1.32332 10.14C0.842515 8.78 1.05839 7.65 1.37238 7.54C1.52938 7.49 4.99316 6.18 8.13313 3.26C8.08407 4.51 8.29013 5.97 8.77094 7.38C9.35968 8.95 10.1545 10.25 11.0572 11.18C6.90658 11.13 3.23674 12.05 3.06993 12.1L3.07974 12.09ZM14.8448 9.33C14.7369 10.36 14.364 11.01 13.8341 11.23C13.7262 11.28 13.6183 11.28 13.5103 11.28C12.9216 11.28 12.2347 10.85 11.489 9.98C12.2347 9.33 12.3917 7.86 11.8128 6.24C11.489 5.43 11.067 4.72 10.5862 4.23C10.1643 3.8 9.68349 3.58 9.20268 3.58C9.20268 3.36 9.20268 3.2 9.25174 3.04C9.35968 2.01 9.73255 1.36 10.2624 1.14C10.3704 1.09 10.4783 1.09 10.5862 1.09C11.646 1.09 13.3043 2.72 14.2561 5.37C14.7859 6.73 15.0018 8.19 14.8448 9.33Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
                <div className="toast-content me-auto">
                  <h6>{title}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <svg style={{minWidth:'13px'}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.664 10.0442C12.114 10.4942 12.114 11.2141 11.664 11.664C11.4421 11.886 11.1481 12 10.8541 12C10.5602 12 10.2662 11.886 10.0442 11.664L6.00075 7.62055L1.95726 11.664C1.72928 11.886 1.44132 12 1.14736 12C0.853393 12 0.55943 11.886 0.337458 11.664C-0.112486 11.2141 -0.112486 10.4942 0.337458 10.0442L4.38095 6.00075L0.337458 1.95726C-0.112486 1.50731 -0.112486 0.787402 0.337458 0.337458C0.781402 -0.112486 1.50731 -0.112486 1.95726 0.337458L6.00075 4.38095L10.0442 0.337458C10.4942 -0.112486 11.2141 -0.112486 11.664 0.337458C12.114 0.781402 12.114 1.50731 11.664 1.95726L7.62055 6.00075L11.664 10.0442Z" fill="#999999"/>
</svg>

                {/* <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                /> */}
              </>
            ) : (
              <>
                <div className="toast-error-bg">
                  <span>
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00057 11.2382C8.49647 11.2382 8.07339 11.6469 8.07339 12.1355C8.07339 12.6241 8.49647 13.0328 9.00057 13.0328C9.50466 13.0328 9.92774 12.6241 9.90073 12.1532C9.91874 11.638 9.50466 11.2293 9.00057 11.2293V11.2382Z" fill="white"/>
<path d="M17.5612 14.4275C18.1463 13.4503 18.1463 12.2954 17.5612 11.3182L11.7731 1.56358C11.197 0.577457 10.1618 0 9.00057 0C7.83935 0 6.80416 0.586341 6.22805 1.56358L0.430962 11.327C-0.145146 12.3132 -0.145146 13.477 0.439964 14.4542C1.01607 15.4137 2.05127 16 3.20348 16H14.7796C15.9409 16 16.9761 15.4137 17.5612 14.4364V14.4275ZM16.3009 13.7257C15.9769 14.2676 15.4098 14.5875 14.7706 14.5875H3.20348C2.57336 14.5875 2.00626 14.2765 1.6912 13.7435C1.37614 13.2104 1.36714 12.5708 1.6912 12.0289L7.48828 2.26541C7.80334 1.73237 8.36145 1.41255 9.00057 1.41255C9.63969 1.41255 10.1978 1.73237 10.5128 2.26541L16.3099 12.0289C16.625 12.553 16.616 13.1838 16.3009 13.7257Z" fill="white"/>
<path d="M8.76652 4.93059C8.32544 5.05497 8.05539 5.43698 8.05539 5.91671C8.07339 6.201 8.1004 6.48529 8.1184 6.76957C8.18141 7.85341 8.24442 8.9106 8.30744 9.99445C8.32544 10.3587 8.6225 10.6252 9.00057 10.6252C9.37864 10.6252 9.67569 10.3409 9.6937 9.96779C9.6937 9.7457 9.6937 9.54137 9.7117 9.31038C9.75671 8.61743 9.79272 7.92449 9.83772 7.23154C9.85573 6.77846 9.90074 6.33426 9.91874 5.88118C9.91874 5.72127 9.90074 5.57912 9.83772 5.42809C9.64869 5.02832 9.20761 4.82399 8.76652 4.92171V4.93059Z" fill="white"/>
</svg>

                    {/* <img src={toastErrorIcon} alt="error" /> */}
                  </span>
                </div>
                <div className="toast-content me-auto">
                  <h6>{type}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <svg style={{minWidth:'13px'}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.664 10.0442C12.114 10.4942 12.114 11.2141 11.664 11.664C11.4421 11.886 11.1481 12 10.8541 12C10.5602 12 10.2662 11.886 10.0442 11.664L6.00075 7.62055L1.95726 11.664C1.72928 11.886 1.44132 12 1.14736 12C0.853393 12 0.55943 11.886 0.337458 11.664C-0.112486 11.2141 -0.112486 10.4942 0.337458 10.0442L4.38095 6.00075L0.337458 1.95726C-0.112486 1.50731 -0.112486 0.787402 0.337458 0.337458C0.781402 -0.112486 1.50731 -0.112486 1.95726 0.337458L6.00075 4.38095L10.0442 0.337458C10.4942 -0.112486 11.2141 -0.112486 11.664 0.337458C12.114 0.781402 12.114 1.50731 11.664 1.95726L7.62055 6.00075L11.664 10.0442Z" fill="#999999"/>
</svg>

                {/* <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                /> */}
              </>
            )}
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toaster;

