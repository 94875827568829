import React from "react";
import toastErrorIcon from "../../assets/images/toast-error.svg";
import { ToastContainer } from "react-bootstrap";
import CloseSvg from "../../assets/images/close.svg";
import Toast from "react-bootstrap/Toast";

const ErrorToaster = ({
  type,
  text = "",

}) => {


  return (
    <ToastContainer>
      <Toast
      >
        <Toast.Body>
          <div className="toast-wrapper pe-0">
              <>
                <div className="toast-error-bg">
                  <span>
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00057 11.2382C8.49647 11.2382 8.07339 11.6469 8.07339 12.1355C8.07339 12.6241 8.49647 13.0328 9.00057 13.0328C9.50466 13.0328 9.92774 12.6241 9.90073 12.1532C9.91874 11.638 9.50466 11.2293 9.00057 11.2293V11.2382Z" fill="white"/>
<path d="M17.5612 14.4275C18.1463 13.4503 18.1463 12.2954 17.5612 11.3182L11.7731 1.56358C11.197 0.577457 10.1618 0 9.00057 0C7.83935 0 6.80416 0.586341 6.22805 1.56358L0.430962 11.327C-0.145146 12.3132 -0.145146 13.477 0.439964 14.4542C1.01607 15.4137 2.05127 16 3.20348 16H14.7796C15.9409 16 16.9761 15.4137 17.5612 14.4364V14.4275ZM16.3009 13.7257C15.9769 14.2676 15.4098 14.5875 14.7706 14.5875H3.20348C2.57336 14.5875 2.00626 14.2765 1.6912 13.7435C1.37614 13.2104 1.36714 12.5708 1.6912 12.0289L7.48828 2.26541C7.80334 1.73237 8.36145 1.41255 9.00057 1.41255C9.63969 1.41255 10.1978 1.73237 10.5128 2.26541L16.3099 12.0289C16.625 12.553 16.616 13.1838 16.3009 13.7257Z" fill="white"/>
<path d="M8.76652 4.93059C8.32544 5.05497 8.05539 5.43698 8.05539 5.91671C8.07339 6.201 8.1004 6.48529 8.1184 6.76957C8.18141 7.85341 8.24442 8.9106 8.30744 9.99445C8.32544 10.3587 8.6225 10.6252 9.00057 10.6252C9.37864 10.6252 9.67569 10.3409 9.6937 9.96779C9.6937 9.7457 9.6937 9.54137 9.7117 9.31038C9.75671 8.61743 9.79272 7.92449 9.83772 7.23154C9.85573 6.77846 9.90074 6.33426 9.91874 5.88118C9.91874 5.72127 9.90074 5.57912 9.83772 5.42809C9.64869 5.02832 9.20761 4.82399 8.76652 4.92171V4.93059Z" fill="white"/>
</svg>

                    {/* <img src={toastErrorIcon} alt="error" /> */}
                  </span>
                </div>
                <div className="toast-content me-auto">
                  <h6>{type}!</h6>
                  <p>{text}</p>
                </div>
                <svg style={{minWidth:'13px'}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.664 10.0442C12.114 10.4942 12.114 11.2141 11.664 11.664C11.4421 11.886 11.1481 12 10.8541 12C10.5602 12 10.2662 11.886 10.0442 11.664L6.00075 7.62055L1.95726 11.664C1.72928 11.886 1.44132 12 1.14736 12C0.853393 12 0.55943 11.886 0.337458 11.664C-0.112486 11.2141 -0.112486 10.4942 0.337458 10.0442L4.38095 6.00075L0.337458 1.95726C-0.112486 1.50731 -0.112486 0.787402 0.337458 0.337458C0.781402 -0.112486 1.50731 -0.112486 1.95726 0.337458L6.00075 4.38095L10.0442 0.337458C10.4942 -0.112486 11.2141 -0.112486 11.664 0.337458C12.114 0.781402 12.114 1.50731 11.664 1.95726L7.62055 6.00075L11.664 10.0442Z" fill="#999999"/>
</svg>

                {/* <img
                  src={CloseSvg}
                  className="toast-close"
                /> */}
              </>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorToaster;

