import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../assets/images/close.svg";
import Loader from "../Loader";
import "../../assets/scss/style.scss";

const ConfirmModal = ({
  size = "md",
  show = false,
  isLoading = false,
  onHide = () => {},
  modalHeading = "",
  children,
  hasActions = true,
  hasAction = true,
  title = "",
  description = "",
  note = false,
  actionBtnText = "Save Changes",
  onActionCallback = () => {},
}) => {
  useEffect(() => {
    setTimeout(() => {
      const backdrop = document.getElementsByClassName("modal-backdrop show");
      try {
        backdrop[1].classList.add("top-backdrop");
      } catch (err) {
      }
    }, 10);
  }, []);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      top
      backdrop="static"
      className="modal"
    >
      {isLoading && <Loader />}
      <div className="modal-header">
        <h1 className="modal-title">{title}</h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src={modalCloseIcon} alt="modal-close-icon" onClick={onHide} />
        </button>
      </div>
      <Modal.Body className="">
        <div className="delete-modal-content">
          <p>{description}</p>
          {note && (
            <p className="note">{note}</p>
          )}
          {hasActions && (
            <div className="modal-button-block">
              <button className="btn submit-btn" onClick={onActionCallback}>
                Yes, Sure
              </button>
              <button
                className="btn cancel-btn"
                data-bs-dismiss="modal"
                onClick={onHide}
              >
                cancel
              </button>
            </div>
          )}
        </div>
        {/* <CloseButton onClick={onHide} />
        <IconAlertTriangle size={56} stroke={1} className="text-danger" />
        {title && <h3>{title}</h3>}
        {description && <p className="text-muted">{description}</p>}
        {children} */}
      </Modal.Body>
      {/* {hasActions && (
        <Modal.Footer className="pt-0 pb-4">
          <Row className="w-100">
            <Col>
              <Button variant="sucess" className="w-100" onClick={onHide}>
                Cancel
              </Button>
            </Col>
            {hasAction && (
              <Col>
                <Button
                  variant="danger"
                  className="w-100"
                  onClick={onActionCallback}
                >
                  {actionBtnText}
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      )} */}
    </Modal>
  );
};

export default ConfirmModal;
