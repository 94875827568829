import React from "react";

const Pending = () => {
  return (
    <>
      <span className="tab-badge pending">pending</span>
    </>
  );
};
export default Pending;
