import React from "react";

const IsCompleted = (props) => {
  return (
    <>
      <span className="tab-badge completed">Completed</span>
    </>
  );
};
export default IsCompleted;
