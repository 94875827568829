import React from "react";

const NotApproved = () => {
  return (
    <>
      <span className="tab-badge not-approved">Not Approved</span>
    </>
  );
};
export default NotApproved;
