import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import ArsIcon from "../assets/images/ars-icon.svg";
import DashboardLogo from "../assets/images/dashboard-logo.svg";
import Svg from "../components/Svg";
import Divider from "../components/Svg/Divider";
import {
  MENU_DATA,
  FIELDREP_MENU_DATA,
  SUPERADMIN_MENU_DATA,
  MENU_DATA_USER_AA,
  MENU_DATA_USER_SU,
} from "../assets/data/menu";
import { isPermission } from "../common/constants";

const Sidebar = ({ isActive, toggleActive }) => {
  const location = useLocation();

  const isDraft = location.pathname.includes("/published");
  const isBroadcast = location.pathname.includes("/broadcastMessages");
  const [hasScroll, setHasScroll] = useState(false);
  const [menuArray, setMenuArray] = useState([]);
  const authSelector = useSelector((auth) => auth?.ars?.authUserReducer?.user);
  const notificationCount = useSelector(
    (auth) => auth?.ars?.notificationCountSlice?.count
  );
  const user_permission = useSelector(
    (auth) => auth?.ars?.authUserReducer?.user_permissions
  );

  useEffect(() => {
    const sidebarElement = document.querySelector(".navigation");
    const handleScroll = () => {
      const scrollTop = sidebarElement.scrollTop;
      const scrollThreshold = 60;
      if (scrollTop > scrollThreshold) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };
    sidebarElement.addEventListener("scroll", handleScroll);
    if (authSelector?.user_type == "A") {
      setMenuArray(MENU_DATA);
    }

    if (authSelector?.user_type == "AA") {
      const updatedMenuArray = user_permission
        .map((permission) => {
          switch (permission?.entity_name) {
            case "Projects":
              return {
                id: 2,
                MenuTitle: "Projects",
                MenuLink: "/projects",
              };
            case "Rounds":
              return {
                id: 3,
                MenuTitle: "Rounds",
                MenuLink: "/rounds",
              };
            case "Surveys":
              return {
                id: 4,
                MenuTitle: "Surveys",
                MenuLink: "/surveys",
              };
            case "Clients":
              return {
                id: 5,
                MenuTitle: "Clients",
                MenuLink: "/clients",
              };
            case "Chains":
              return {
                id: 6,
                MenuTitle: "Chains",
                MenuLink: "/chains",
              };
            case "Sites":
              return {
                id: 7,
                MenuTitle: "Sites",
                MenuLink: "/sites",
              };
            case "Fieldreps":
              return {
                id: 8,
                MenuTitle: "Fieldreps",
                MenuLink: "/fieldreps",
              };
            case "Survey Template":
              return {
                id: 9,
                MenuTitle: "Survey Templates",
                MenuLink: "/drafts",
              };
            case "Recruitment":
              return {
                id: 11,
                MenuTitle: "Recruitment",
                MenuLink: "/recruitment",
              };
            case "Import":
              const menuData = {
                id: 14,
                MenuTitle: "Imports",
                MenuLink: "/imports",
              };
              if (isPermission("import_survey")) {
                menuData.subMenu = {
                  id: 15,
                  MenuTitle: "Export Survey",
                  MenuLink: "/exportSurvey",
                };
              }
              return menuData;
            default:
              return null;
          }
        })
        .filter((item) => item !== null);
      setMenuArray(
        [...MENU_DATA_USER_AA, ...updatedMenuArray].sort((a, b) => a.id - b.id)
      );
    } else if (authSelector?.user_type == "F") {
      setMenuArray(FIELDREP_MENU_DATA);
    } else if (authSelector?.user_type == "S") {
      setMenuArray(SUPERADMIN_MENU_DATA);
    } else if (authSelector?.user_type == "SU") {
      const updatedMenuArray = user_permission
        .map((permission) => {
          switch (permission?.entity_name) {
            case "Inquiries":
              return {
                id: 2,
                MenuTitle: "Inquiries",
                MenuLink: "/sa/inquiries",
              };
            case "Customers":
              return {
                id: 3,
                MenuTitle: "Customers",
                MenuLink: "/sa/customers",
              };
            case "Billing":
              return {
                id: 4,
                MenuTitle: "Billing",
                MenuLink: "/sa/billing",
              };

            case "Tickets":
              return {
                id: 9,
                MenuTitle: "Tickets (Report an Issue)",
                MenuLink: "/sa/tickets",
              };

            default:
              return null;
          }
        })
        .filter((item) => item !== null);
      setMenuArray(
        [...MENU_DATA_USER_SU, ...updatedMenuArray].sort((a, b) => a.id - b.id)
      );
    }

    return () => {
      sidebarElement?.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const toggleSidebar = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 1016) {
      if (isActive === true) {
        toggleActive();
      }
    }
  };
  return (
    <>
      <aside>
        <div
          className={`navigation ${isActive ? "active" : ""} ${
            hasScroll ? "has-scroll" : ""
          }`}
        >
          <NavLink to={menuArray[0]?.MenuLink} className="logo-wrapper">
            <img src={ArsIcon} alt="ArsIcon" loading="lazy"/>
            <img src={DashboardLogo} alt="Dashboard-logo" />
          </NavLink>
          <div className="toggle-mob d-none">
            <i className="fa-solid fa-bars"></i>
          </div>
          <ul>
            {menuArray?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {isActive ? (
                    <>
                      <li>
                        <>
                          <div onClick={() => toggleSidebar()}>
                            {authSelector.user_type !== "AA" && (
                              <Divider MenuLink={item.MenuLink}></Divider>
                            )}
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 50, hide: 0 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-${i}`}
                                  className="custom-tooltip"
                                >
                                  {item.MenuTitle}
                                </Tooltip>
                              }
                            >
                              <NavLink to={item.MenuLink}>
                                <div>
                                  <span className="icon">
                                    <Svg MenuLink={item.MenuLink}></Svg>
                                  </span>
                                  <span className="title">
                                    {item.MenuTitle}
                                  </span>
                                  {/* {item.MenuTitle === 'Broadcast Messages' && <span className="badge-white ms-2">3</span>} */}
                                </div>
                              </NavLink>
                            </OverlayTrigger>
                          </div>
                        </>
                      </li>
                    </>
                  ) : (
                    <>
                      <li key={i}>
                        <div
                          onClick={() => toggleSidebar()}
                          id={`sidebar-${i}`}
                        >
                          {authSelector.user_type !== "AA" && (
                            <Divider MenuLink={item.MenuLink}></Divider>
                          )}
                          <NavLink
                            to={item.MenuLink}
                            className={isDraft && item.id == 9 ? "active" : ""}
                          >
                            <div>
                              <span className="icon">
                                <Svg MenuLink={item.MenuLink}></Svg>
                              </span>
                              <span className="title">{item.MenuTitle}</span>
                              {item.MenuTitle === "Broadcast Messages" &&
                                authSelector?.user_type != "S" &&
                                notificationCount > 0 && (
                                  <span
                                    className={`ms-2 ${
                                      isBroadcast
                                        ? "badge-white"
                                        : "badge-inactive"
                                    }`}
                                  >
                                    {notificationCount}
                                  </span>
                                )}
                            </div>
                          </NavLink>
                        </div>
                      </li>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
          <div className="contact-block d-none">
            <i className="bi bi-headset"></i>
            <div>
              <a href="mailto:customercare@utside.com">
                customercare@utside.com
              </a>
              <a href="tel:+91-12345 67890">+91-12345 67890</a>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
