import { IconUser } from "@tabler/icons-react"
import moment from "moment"
import "moment-timezone"

class Utils {
  static haveSameData = (obj1, obj2) => {
    const obj1Length = Object.keys(obj1).length
    const obj2Length = Object.keys(obj2).length

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key])
    }
    return false
  }
  static getAcronym = (name) => {
    let res = ""
    name = name.split(" ")
    if (name.length < 2) {
      res = (name[0][0] || "") + (name[0][1] || "")
    } else {
      let i = 0
      name.forEach((item) => {
        const [char] = item
        i++
        if (i < 3) {
          res += char
        }
      })
    }
    return res.toUpperCase()
  }
  static priceFormat = (price) => {
    const dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    })
    return dollarUS.format(price)
  }
  static getBase64Image = (file) => {
    return new Promise((resolve) => {
      let baseURL = ""
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }
  static getFormData = (object) => {
    // return Object.entries(o).reduce((d,e) => (d.append(...e),d), new FormData())
    const formData = new FormData()
    Object.keys(object).forEach((key) => {
      if (key === "avatar" || key === "material" || key === "models") {
        formData.append(key, object[key][0])
      } else {
        formData.append(key, object[key])
      }
      // formData.append(key, (key==="avatar" || key==="material")?object[key][0]:object[key])
    })
    return formData
  }

  static formatDateToYYYYMMDD(dateString) {
    const dateObj = new Date(dateString)

    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  static filterArrayWithCommaSeparatedValues(originalArray, commaSeparatedValues) {
    const filterValuesArray = commaSeparatedValues.split(",")
    const filteredArray = originalArray.filter((item) => {
      return filterValuesArray.includes(item.label)
    })
    return filteredArray
  }

  static downloadFile = async (fileName) => {
    const fileUrl = process.env.REACT_APP_RELATIVE_URL + fileName
    const response = await fetch(fileUrl)
    const blob = await response.blob()

    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = fileName

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  // gmt to iso according timezone
  static convertToUTCWithTimezone(date, timezone) {
    const localDate = moment(date)
    const tzDate = localDate.tz(timezone)
    return tzDate.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
  }
  static localToUTC(localDate, timeZone) {
    return moment.tz(localDate, timeZone).utc().format()
  }

  static convertLocalToTimezonetest(startDate, startTime, targetTimezone) {
    const combinedDateTime = `${startDate} ${startTime}`

    // Parse the combined string as UTC time
    const utcDateTime = moment.utc(combinedDateTime, "YYYY-MM-DD HH:mm:ss")

    // Convert to UTC-5 by subtracting 5 hours
    const utcMinus5Time = utcDateTime.add(1, "hours")

    // Convert from adjusted UTC to the target timezone (America/New_York)
    const convertedTime = utcMinus5Time.tz(targetTimezone)

    // Format the output to the desired format
    return convertedTime.format("DD MMM YYYY hh:mm A")
  }

  //check remaining
  static convertLocalToTimezone(startDate, startTime, targetTimezone) {
    const combinedDateTime = `${startDate} ${startTime}`
    const nyTime = moment.tz(combinedDateTime, "UTC").tz(targetTimezone)

    // Format the date to MM/DD/YYYY HH:MM AM/PM
    return nyTime.format("MM/DD/YYYY hh:mm A")
    // const utcDateTime = moment.utc(combinedDateTime, "YYYY-MM-DD HH:mm:ss");
    // const convertedTime = utcDateTime.tz(targetTimezone);
    // return convertedTime.format("MM/DD/YYYY hh:mm A");
  }
  static convertLocalToTimezoneDate(startDate, startTime, targetTimezone) {
    const combinedDateTime = `${startDate} ${startTime}`
    const nyTime = moment.tz(combinedDateTime, "UTC").tz(targetTimezone)
    return nyTime.format("YYYY-MM-DD")
  }
  static convertDatetoTimezone(startDate, targetTimezone) {
    const convertedDate = moment.tz(startDate, "YYYY-MM-DD", targetTimezone)
    console.log(convertedDate.format("MM/DD/YYYY hh:mm A"))
    return convertedDate.format("MM/DD/YYYY hh:mm A")
  }

  static convertRoundFilterDate(startDate, targetTimezone) {
    // const formattedDate = moment(startDate).tz(targetTimezone).format('YYYY/MM/DD');
    const formattedDate = moment.tz(startDate, "UTC").tz(targetTimezone)
    console.log("🚀 ~ Utils ~ convertRoundFilterDate ~ formattedDate:", formattedDate)
    return formattedDate.format("YYYY/MM/DD")
  }
  static utcToLocal(utcDate, timeZone) {
    return moment.utc(utcDate).tz(timeZone).format("DD MMM YYYY hh:mm A")
  }

  static convertToChicagoTimezone(utcDate, timeZone) {
    // Create a Date object from the given UTC date
    const date = new Date(utcDate)

    const options = {
      timeZone: timeZone,
      year: "numeric",
      month: "short", // Oct
      day: "2-digit", // 03
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // For AM/PM format
    }

    // Format the date in the desired format
    const formatter = new Intl.DateTimeFormat("en-US", options)
    const parts = formatter.formatToParts(date)

    // Assemble the date in the desired format: 03 Oct 2024 06:20 AM
    const formattedDate = `${parts.find((p) => p.type === "day").value} ${
      parts.find((p) => p.type === "month").value
    } ${parts.find((p) => p.type === "year").value} ${parts.find((p) => p.type === "hour").value}:${
      parts.find((p) => p.type === "minute").value
    } ${parts.find((p) => p.type === "dayPeriod").value}`

    return formattedDate
  }
  static convertToEditTimezone(utcDate, timeZone) {
    // Create a Date object from the given UTC date
    const date = new Date(utcDate)

    const options = {
      timeZone: timeZone,
      year: "numeric",
      month: "short", // Oct
      day: "2-digit", // 03
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // For AM/PM format
    }

    // Format the date in the desired format
    const formatter = new Intl.DateTimeFormat("en-US", options)
    const parts = formatter.formatToParts(date)

    // Assemble the date in the desired format: 03 Oct 2024 06:20 AM
    const formattedDate = `${parts.find((p) => p.type === "day").value} ${
      parts.find((p) => p.type === "month").value
    } ${parts.find((p) => p.type === "year").value} ${parts.find((p) => p.type === "hour").value}:${
      parts.find((p) => p.type === "minute").value
    } ${parts.find((p) => p.type === "dayPeriod").value}`
    const selectedDate = new Date(formattedDate)
    return selectedDate
  }
  static convertToUTC(localDateTime) {
    // Create a new Date object using the local date and time string
    const localDate = new Date(localDateTime)

    // Get the UTC time using toISOString()
    const utcDateTime = localDate.toISOString()

    return utcDateTime
  }
  static compareDeadline(startDate, startTime, targetTimezone) {
    console.log("🚀 ~ Utils ~ convertLocalToTimezone ~ targetTimezone:", targetTimezone)

    const combinedDateTime = `${startDate} ${startTime}`
    const nyTime = moment.tz(combinedDateTime, "YYYY-MM-DD HH:mm:ss", "UTC").tz(targetTimezone)

    // Instead of returning a formatted string, return the moment object
    return nyTime
  }
  static updateScheduledCounts(records, timeZoneItem) {
    const todayDate = moment().tz(timeZoneItem)

    return records.map((record) => {
      if (!record.assignments || !Array.isArray(record.assignments)) {
        return { ...record, scheduledCount: 0 }
      }
      const deadlineDate = Utils.compareDeadline(
        record.deadline_date,
        record.deadline_time,
        timeZoneItem
      )

      let scheduledCount = 0
      const len = record.assignments.length
      for (let i = 0; i < len; i++) {
        const assignment = record.assignments[i]
        if (assignment?.is_reported === 1 || assignment?.is_scheduled !== 1) {
          continue
        }
        if (record.deadline_date && todayDate.isBefore(deadlineDate)) {
          scheduledCount++
        }
      }
      return { ...record, scheduledCount }
    })
  }

  static downloadFileErrorImport = async (fileName) => {
    // const fileUrl = process.env.REACT_APP_RELATIVE_URL + fileName
    const fileUrl = process.env.REACT_APP_RELATIVE_URL + "/uploads/imports/" + fileName
    const response = await fetch(fileUrl)
    const blob = await response.blob()

    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = fileName

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
export default Utils
