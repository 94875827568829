import React from "react";

const Active = (props) => {
  return (
    <>
      <div className="table-data text-center">
        <span className="tab-badge completed">active</span>
      </div>
    </>
  );
};
export default Active;
