import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../src/assets/css/style.css";
import "../src/assets/css/toaster.css";
// import "../src/assets/css/nice-select.css";
// import "../src/assets/css/component.css";
import "../src/assets/css/react-select.css";
import "../src/assets/css/react-datepicker.css";
import "../src/assets/scss/component.scss";
import "../src/assets/scss/sidebar.scss";
import "../src/assets/scss/style.scss";
import "../src/assets/scss/custom.scss";
import "../src/assets/scss/_variables.scss";
import "../src/assets/scss/_mixins.scss";
import "../src/assets/scss/_buttons.scss";
// import "../src/assets/css/sidebar.css";
// import "jquery-nice-select/css/style.css";

import Layout from "./layout";
import axios from "axios";
import { showUpdatedToasterMessage } from "./store/slices/toaster/toasterslice";
import { useDispatch } from "react-redux";
import ErrorToaster from "./components/Toaster/ErrorToaster";




axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(

  (request) => {
    const { authUserReducer: authSelector } = store.getState()["ars"];
    request.headers.Authorization = `Bearer ${authSelector?.access_token}`;
    request.headers.Accesscode = authSelector?.accesscode;
    return request;
  },
  (error) => Promise.reject(error),
);

// axios.interceptors.response.use(
//   (response) => {

//     if (response?.data?.error?.code == 401 && response?.data?.error?.message === "Unauthorized") {
    
//       toast(<ErrorToaster text={'Your session is Expired'} type={'Error'}/>, {
//         autoClose: false,        
//           hideProgressBar: true,   
//           closeButton: false,      
//           icon: false,               
//           className: 'error-toaster',
//           position: "top-right",
//       })
//       setTimeout(() => {
//         console.log('toasted');
//         localStorage.clear()
//         window.location.href = "/login"
//       }, 1000);

//       return
//     }
//     else {

//       return response;
//     }
//   },
//   (error) => {
//     console.log("🚀 ~ error:main index", error)
//     // console.log("🚀 ~ error:", error?.response?.data?.message)


//     return Promise.reject(error);
//   },
// );




let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <Provider store={store}>
      <ToastContainer autoClose={7000} limit={1}/>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </>
  // </React.StrictMode>
);
