import React from "react";

const Offered = () => {
  return (
    <>
      <span className="tab-badge offer">offered</span>
    </>
  );
};
export default Offered;
