import React, { useState, Suspense } from "react"
import { useRoutes } from "react-router-dom"
import Loader from "../components/Loader"
import useLogin from "../hooks/useLogin"
import SiteHeader from "../interface/Header"
const InjectProtectedNode = ({ authSelector }) => {
  //const authSelector = useSelector((state) => state.ars.authUserReducer);
  const [isActive, setIsActive] = useState(false)
  const toggleActive = () => {
    setIsActive((active) => {
      return !active
    })
  }
  const getRoles = useLogin(authSelector, isActive)
  
  return (
    <SiteHeader isActive={isActive} toggleActive={toggleActive}>
      <Suspense fallback={<Loader top="64px" height="calc(100% - 64px)" />}>
        {useRoutes(getRoles)}
      </Suspense>
    </SiteHeader>
  )
}

export default InjectProtectedNode
